import './WelcomeForm.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import EntryForm2 from './EntryForm2';
import Hexapi from '../../utility/HexAPI/Hexapi';
import { useEffect, useState } from 'react';
import hex from '../../utility/Setimage/image/WelcomeScreen.png'
import Urlencoder from '../../utility/Urlencoder';
import { Aesdecryption } from '../../utility/Aes/Aes';

const WelcomeFrom = () => {
    const[datedata,setdatedata]=useState("")
   
    const [login, setlogin] = useState(false)
    const [organisationname, setoraganisation] = useState('')
    const loginentry = () => {
        setlogin(true)
    }
 const handleback =()=>{
    setlogin(false)
 }

    const decodeUrl = (data) => {
        console.log(data,"data")
        if (Aesdecryption(data.split('?')[1]) !== "") {
            console.log(data.split('?'),"data111111111111111111")
            let enctext = data.split('?')[1]
            let dectext = `?${btoa(Aesdecryption(enctext))}`
            console.log(Urlencoder(data.split('?')[0] + '/' + dectext),"sgdcidcdc")
            return Urlencoder(data.split('?')[0] + '/' + dectext)
           
        } else {
            return Urlencoder(data)
        }
    }
    const[data,setdata]=useState(decodeUrl(window.location.href))
    useEffect(() => {
        // setoraganisation(data.orgdbname)
       console.log(data,"data")

        console.log("useEffect is running");

        let obj = {
            'query': `[dbo].[VRApp_Web_Proc_EntryForm_FormGetDataV2]`,
            'orgdbname': `${data.orgdbname}`,
            'queryArr': []
        }
        // console.log(data.orgdbname)
        Hexapi(obj).then((resp) => {
            console.log(resp,"resp")
            setoraganisation(resp[''][0]['orgname'])
        }).catch(err => console.log(err))

    },[login,data])

    useEffect(() => {
        
        console.log("useEffect is running");
        const date = new Date();
        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            day: 'numeric',
            month: 'short',
            year: '2-digit'
        };

        const formattedDate = date.toLocaleString('en-US', options);
        console.log(formattedDate, "formattedDate");

        setdatedata(formattedDate);
    }, []);

    const handleform = () => {
        return ( 
            login ?
                <EntryForm2 datedata={datedata} orgdata={data}/> :
                <Form className='width-set'>
                    <div className='center-data'>
                    
                    <Form.Group className="welcome_from">
                        <Form.Label><h4 className='font-data'> Welcome To {organisationname}</h4></Form.Label><br />
                        <div className='mainbuttons'>
                            <Button style={{ backgroundColor: 'black' }} onClick={loginentry}>Register a visitor</Button>
                        </div>

                        <Form.Label>
                            <div>
                                <img src={hex} alt='..' className=' img-fluid img-data'/>
                            </div>
                        </Form.Label>
                    
                            <div id=''>
                                <span id='logolebal'>Desgined By</span><br />
                                <img src="https://vrms.hexbss.xyz/web/entryform/images/hexlogo.png" alt='..' id='hexlogo' />
                            </div>
                       
                        <br />
                    </Form.Group>
                    </div>
                  
                </Form>
        )
    }
    return (
        <div className='body-text-2'>
             <nav onClick={handleback} className='point-cur'><a >
                        <img src="https://vrms.hexbss.xyz/web/entryform/images/vmslogo final.png" style={{ marginLeft: "50px", width: "30px", height: "30px", position: 'relative', top: 10, right: 10 }} alt=""></img>
                        <img src="https://vrms.hexbss.xyz/web/entryform/images/VMSTXT copy.png" style={{ marginLeft: "20px", marginRight: "25px", width: "50px", height: "30px", position: 'relative', top: 40, right: 73 }} alt=""></img>

                    </a></nav>
                    <div className='body-text'>
            {
                handleform()

            }
        </div>
        </div>
       
    )
}
export default WelcomeFrom;