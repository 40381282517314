import { createSlice } from "@reduxjs/toolkit";

const authslice = createSlice({
    name: "auth",
    initialState: {
        isAuthenticated: false,
        user: null,
        loading: false,
        error: null
    },
    reducers: {
        loginstart(state) {
            state.loading = true;
            state.error = null;
        },
        loginsuccess(state, action) {
            state.loading = false;
                state.isAuthenticated = true;
                state.error = null;
                state.user = action.payload;
                sessionStorage.setItem("login","true")
        },
        loginfailure(state, action) {
            state.loading = false;
                state.isAuthenticated = false;
                state.error = action.payload;
        }
    }
})

export const { loginstart, loginsuccess, loginfailure } = authslice.actions;
export default authslice.reducer;