import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import './TableStyle.css'
import TimeFormatter from '../../../utility/newTimeformatter/TimeFormatter';


var oldData = []
export default class Datatable extends Component {
    constructor() {
        super()
        this.state = {
            rowData: [], isDataFound: false, currentPaginationPage: 0, showPaginationRows: 0,
            isShowSearchBox: false, searchValue: '', selectedcolumns: ['persontomeet', 'priority', 'purpose', 'intime', 'nextfollowup']
        }
    }
    componentDidMount() {
        const updatedData=this.props.data.map((item)=>({
            intime: item.intime,...item

        }))

        // const updatedData = resp[""].map((item) => ({
        //     lcx: item.lcx,
        //     name: item.name,
        //     Icons: item.Icons,
        //     ...item,
        // }));
        console.warn(this.props.data.length, this.props.data)
        this.setState({ rowData: updatedData, isDataFound: true })
        oldData = updatedData
        // this.props.pagination && this.setState({ showPaginationRows: this.props.pagination.rowsPerPage })
    }
    getHeader(data) {
        // console.log(data);
        console.log(Object.keys(data[0]));
        return Object.keys(data[0]).filter(col => col !="visitorid").map((s) => {
            // console.log(heading)
            // return <th key={heading}>{heading}</th>

            // console.log(s)
            {
                if (s != "recid") {
                    if (s == "visitorid") {
                        return <th>Visitor ID</th>
                    }
                    if (s == "firstname") {
                        return <th>First Name</th>
                    }
                    if (s == "lastname") {
                        return <th>Last Name</th>
                    }
                    if (s == "contactnumber") {
                        return (
                            <th>Contact Number</th>
                        )
                    }
                    if (s == "visitorcount") {
                        return (
                            <th>No. Of Visitor</th>
                        )
                    }
                    if (s == "persontomeet") {
                        return (
                            <th>Person to Meet</th>
                        )
                    }
                    if (s == "purpose") {
                        return (
                            <th>Purpose</th>
                        )
                    }
                    if (s == "visitortype") {
                        return (
                            <th>Visitor Type</th>

                        )
                    }
                    if (s == "visitstatus") {
                        return (
                            <th>Visit Status</th>
                        )
                    }
                    if (s == "intime") {
                        return (
                            <th>In Time</th>
                        )
                    }
                    if (s == "outtime") {
                        return (
                            <th>Out Time</th>
                        )
                    }
                    if (s == "companyname") {
                        return (
                            <th>Company Name</th>
                        )
                    }

                    return (
                        <th>{s}</th>

                    )
                }
            }






        })
    }
    clearSearch(e) {
        this.setState({ rowData: oldData, isDataFound: true, isShowSearchBox: false, searchValue: '' })
    }
    setDate(date) {
        if (date != undefined) {
            // return date.split(' ')[0]
            if (date != undefined) {
                // return date.split(' ')[0]
                console.log(date)
                var transformdate = date.split(".")[0];
                console.log(transformdate)
                //   console.log(date);
                var exitdate = TimeFormatter(transformdate);
                console.log(exitdate);
                var visitorinfo = this.state.visitorinfo
                //   console.log(visitorinfo)
                // console.log(this.state.visitorinfo)
                var newReverseDate =
                    exitdate.ShiftTime +
                    "," +
                    exitdate.userSplitedDate[2] +
                    "-" +
                    exitdate.ShiftMonth +
                    "-" +
                    exitdate.CurYear.toString().slice(-2);
                console.log(newReverseDate);

                return newReverseDate;
            }
        }
    }
    getRowsData(data) {
        var newTableData = [].concat(data)
        let newData = newTableData
        // if (this.props.pagination) {
        //     newData = newTableData.filter((row, index) => {
        //         let rowPerPage = this.state.showPaginationRows < 0 ? newTableData.length : this.state.showPaginationRows
        //         let start = this.state.currentPaginationPage * rowPerPage
        //         let end = (this.state.currentPaginationPage + 1) * rowPerPage
        //         if (index >= start && index < end) return true
        //     })
        // }
        return newData.map((row, i) => {
            return (
                <tr key={i}
                // onClick={() => this.props.onRowClick(row)}
                >

                    {
                        Object.keys(row).filter(item => item!="visitorid").map((heading, i) => {
                            if (typeof row[heading] != 'object' && row[heading] != 'null') {
                                return <td key={heading} dangerouslySetInnerHTML={{ __html: row[heading] }} />
                            }
                            else if (row[heading] == null) {
                                return <td ></td>
                            }
                            else if (heading == "visitstatus") {
                                if (row[heading] == 1) {
                                    row[heading] = 'IN';
                                } else if (row[heading] == -1) {
                                    row[heading] = 'OUT';
                                }
                            }
                            // else if (heading == 'intime') {
                            //     let text = row[heading].date;
                            //     text=moment(text).format("DD/MM/YYYY");
                            //     return <td key={row}>
                            //         {text}
                            //     </td>
                            // } 
                            else {
                                return <td key={row} dangerouslySetInnerHTML={{ __html: this.setDate(row[heading].date) }} />
                            }
                        }
                        )
                    }
                </tr>
            )
        })
    }



    storeSearch(e) {
        console.log(oldData)
        let value = e.target.value
        this.setState({ searchValue: value })
        let applyFilter = Object.keys(oldData[0])

        // console.log(value)
        // console.log(applyFilter)
        if (value == '') {
            if (oldData.length > 0) {
                console.log(oldData)
                this.setState({ rowData: oldData, isDataFound: true })
                // console.log(rowData)
            } else {
                this.setState({ isDataFound: false })
            }
        } else {
            if ((oldData.filter((row) => {
                if (Array.isArray(applyFilter)) {
                    let StrArr = []
                    for (let i = 0; i < applyFilter.length; i++) {
                        StrArr.push(row[`${applyFilter[i]}`])
                        // console.log(StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase()))
                        // console.log(StrArr)
                    }
                    return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                } else {
                    return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())
                }
            })).length > 0) {
                this.setState({
                    rowData: (oldData.filter((row) => {
                        if (Array.isArray(applyFilter)) {
                            let StrArr = []
                            for (let i = 0; i < applyFilter.length; i++) {
                                StrArr.push(row[`${applyFilter[i]}`])
                                // console.log(StrArr)
                            }

                            return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                            // console.log(StrArr)
                        } else {
                            return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())()
                            // console.log(StrArr)
                        }
                    }))
                    , isDataFound: true
                }, () => {
                    this.setState({ currentPaginationPage: 0 })
                })


            } else {
                this.setState({ isDataFound: false })
            }
        }
    }


    render() {
        console.log(this.state.rowData)
        return (
            <>
                <div style={{ height: '200px' }}>

                    <div style={{ position: 'relative', height: "inherit" }}>
                        {/* {
                            this.props.isNavbar &&
                            <div className="_tablenavbar" style={{ backgroundColor: 'rgb(153, 135, 170)' }}>
                                <div className="_left">
                                    <span>{this.props.heading}</span>
                                </div>
                                <div className="_right">
                                    <input type="text" id="searchBarFocus" autoFocus={true} value={this.state.searchValue} style={{ width: this.state.isShowSearchBox ? "250px" : '0px', padding: this.state.isShowSearchBox ? "6px 12px" : "6px 0px", marginLeft: this.state.isShowSearchBox ? "10px" : '0px' }} className="editable" placeholder='Search...' onChange={(e) => this.storeSearch(e)} />
                                    {
                                        this.state.isShowSearchBox ?
                                            <Tooltip title="Close Search">
                                                <IconButton style={{ color: '#fff' }} onClick={() => this.clearSearch()}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>

                                            :
                                            <Tooltip title="Open Search">
                                                <IconButton style={{ color: '#fff' }} onClick={() => this.setState({ isShowSearchBox: true }, () => {
                                                    if (document.getElementById('searchBarFocus')) {
                                                        document.getElementById('searchBarFocus').focus()
                                                    }
                                                })}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </Tooltip>

                                    }
                                </div>
                            </div>
                        } */}
                        <Table id='_tableGrid' className={'table-hover'} responsive style={{ width: "140%" }}>
                            <thead style={this.props.headStyle}>
                                <tr>
                                    {this.state.isDataFound &&
                                        this.state.rowData.length > 0 && this.getHeader(this.state.rowData)}
                                </tr>
                            </thead>
                            {

                                this.state.isDataFound &&
                                    this.state.rowData.length > 0 &&
                                    this.state.isDataFound ?
                                    <tbody>
                                        {
                                            this.getRowsData(this.state.rowData)
                                        }
                                    </tbody>
                                    :

                                    <div style={{ marginTop: '10%', marginLeft: '15cm' }}>
                                        <h3>No Data Found!</h3>
                                    </div>

                            }
                        </Table>
                        {/* {
                            this.props.pagination &&
                            this.state.isDataFound &&
                            <div className="_tablefooter" style={{ backgroundColor: 'rgb(153, 135, 170)' }}>
                                <PaginationBar
                                    rowsPerPage={this.props.pagination.rowsPerPage}
                                    count={this.state.rowData.length}
                                    rowsPerPageOptions={this.props.pagination.rowsPerPageOptions}
                                    onPageChange={(currentPage) => this.setState({ currentPaginationPage: currentPage })}
                                    onRowsChange={(rows) => this.setState({ showPaginationRows: rows, currentPaginationPage: 0 })}
                                />
                            </div>
                        } */}

                    </div>
                </div>

            </>

        )
    }
}
