import React, { Component, useEffect, useState } from 'react'
import './SideBar.css'
import menuBtn from '../assets/menu.png'
import "./HeaderBar.css"
import Hexapi from '../utility/HexAPI/Hexapi'
import { Aesdecryption, Aesencryption } from '../utility/Aes/Aes'
import { QrReader } from 'react-qr-reader'
import DocumentStack from '../assets/document_icon.png'
import Reports from '../assets/report_icon.png'
import Home from '../assets/home_icon.png'
import Logout from '../assets/logout_icon.png'
import './contentBox.css'
import { confirmAlert } from 'react-confirm-alert'
import QRCode from "react-qr-code";
import Pdf from "react-to-pdf";
import { Modal } from "react-bootstrap"
import { StringFormatter } from '../utility/HexAPI/StringFormatter'
import Axios from 'axios'
import Tint from '../utility/ImageTint/Tint'
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Sidebar from 'react-sidebar'
const QRcoderef = React.createRef();

export const SideBar=()=>{

    const location=useLocation()
    const [showWidth, setShowWidth] = useState('280px');
    const [setDisplay, setSetDisplay] = useState("block");
    const [contentBoxClass, setContentBoxClass] = useState("col-md-9");
    const [navIcon, setNavIcon] = useState('');
    const [data, setData] = useState([]);
    // const [notifications, setNotifications] = useState([]);
    const [activeLink, setActiveLink] = useState('Home');
    // const [hidenotify, setHideNotify] = useState(false);
    // const [isDisable, setIsDisable] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [branches, setBranches] = useState([]);
    const [branchid, setBranchId] = useState("");
    const [notificationCount, setNotificationCount] = useState(0);
    const [isBellClicked, setIsBellClicked] = useState(false);
    const [qrConfig, setQrConfig] = useState("");
    // const [branchName,setbranchName]=useState('')
    // const [orgname,setorgname]=useState('')
    const usercode = localStorage.getItem('usercode') || '14D9EC' ;
    const getPhotoUrl = `${sessionStorage.getItem('Apipathurl')}${sessionStorage.getItem('apiname')}`;
    const empid = sessionStorage.getItem('empid');
    const userid = sessionStorage.getItem('userid');
    const clientid = sessionStorage.getItem('clientid');
    const clientname = sessionStorage.getItem('clientname');
    const usertype = sessionStorage.getItem('usertype');
    const checkSessionUrl = `${sessionStorage.getItem('Apipathurl')}CheckSession.php`;
    const Clientdetails = navigator.userAgent;
    const[isnewModalOpen,setisnewModalOpen]=useState(false)
    const navigate=useNavigate()


    const handleQRcode = () => setIsModalOpen(true);
    const closemodal = () => setIsModalOpen(false);

    const checksession = () => {
        return new Promise((resolve, reject) => {
            let fd = new FormData();
            axios({
                mode: 'cors',
                method: "POST",
                headers: { "Content-Type": "multipart/form-data" },
                url: checkSessionUrl,
                data: fd
            }).then(res => {
                console.log(res, "check session");
                if (res.data !== 2) {
                      navigate("/login")
                    console.log("session expired");
                    reject('sessions are expired');
                } else {
                    resolve();
                }
            }).catch(err => console.error(err));
        });
    };

    const hideSidebar = () => {
        setShowWidth("0px");
        setSetDisplay("none");
        setContentBoxClass("col-md-12");
    };

    const showSidebar = () => {
        setShowWidth("280px");
        setSetDisplay("block");
        setContentBoxClass("col-md-9");
    };

    const orgdbname = () => {
        let obj = {
            'query': `[dbo].[VRApp_Web_Proc_Organisation_GetDetails]`,
            'orgdbname': `${sessionStorage.getItem('orgdbname')}`,
            'apipath': `${sessionStorage.getItem('Apipathurl')}`,
            'queryArr': []
        };
        console.log(obj,)
        Hexapi(obj).then(resp => {
            console.log(resp);
            let orgname = resp[""][0]['orgname'];
            setQrConfig(resp[""][0]['qrconfig']);
            sessionStorage.setItem('orgname', orgname);
            console.log(qrConfig);
        }).catch(err => console.error(err));
    };
    

    const addActiveClass = (name, icon) => {
        setActiveLink(name);
        setNavIcon(icon);
    };
    const setContentBox = (name, targetPage) => {
        console.log(targetPage,"targetpage")
        if (targetPage !== undefined) {
            if (document.getElementById('contentBox')) {
                if (targetPage === "Logout") {
                    confirmBox();
                } 
                if (targetPage !== undefined && targetPage !== "Logout") {
                    return <Link to={`/${targetPage}`} />;
                }
            }
        }
    };

    const confirmBox = () => {
        confirmAlert({
            message: 'Are you sure you want to Logout?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        localStorage.clear();
                        sessionStorage.removeItem('hash');
                        sessionStorage.removeItem('login');
                        sessionStorage.removeItem('url');
                        sessionStorage.removeItem('orgname');
                        sessionStorage.removeItem('orgdbname');
                        sessionStorage.removeItem('loginurl');
                        sessionStorage.removeItem('console');
                        sessionStorage.removeItem('userid');
                        navigate("/login")
                    },
                    className: 'yes-button'
                },
                {
                    label: 'No',
                    onClick: () => {
                        // navigate("/HomePage")
                        // addActiveClass("Home", Home);
                        // setContentBox("HomePage");
                    },
                     className: 'no-button'
                }
            ]
        });
    };
  
   const handlecode=()=>{
        setisnewModalOpen (true)
    }

    const newclosemodal=()=> {
        setisnewModalOpen(false)
    }

    useEffect(() => {
        orgdbname();

        if (sessionStorage.getItem('login') === "true") {
            const pathToSidebarMap = {
                "HomePage": "Home",
                "Visitorlogs": "Visitor logs"
            };
            let pathName = location.pathname.substring(1);
            pathName = pathToSidebarMap[pathName] ;
            if (pathName){
                console.log(pathName, "Extracted Path");
                const sideBarItem = sideBarDetails?.find(item => item.name === pathName);
                if (sideBarItem) {
                    addActiveClass?.(sideBarItem.name, sideBarItem.icon);
                } else {
                    console.log("No sidebar item");
                }
            } else {
                console.log("No valid path");
                addActiveClass("Home", Home);
            }
        } else {
            console.log("User is not logged in");
        }
        // let firstIntervalID = false;
        // let newIntervalID = false;
        
        // const getInfo = (isrun) => {
        //     if (isrun) {
        //         firstIntervalID = setInterval(() => {
        //             console.log('first interval calling');
        //             // checksession();
        //         }, 30000);
        //     } else {
        //         newIntervalID = setInterval(() => {
        //             console.log('new interval calling');
        //             // checksession();
        //         }, 30000);
        //     }
        // };
        // getInfo(0);
        // const handleVisibilityChange = (event) => {
        //     event.preventDefault();
        //     if (document.visibilityState !== "visible") {
        //         console.log("tab is inactive", new Date());
        //         clearInterval(firstIntervalID);
        //         clearInterval(newIntervalID);
        //     } else {
        //         console.log("tab is active", new Date());
        //         getInfo(0);
        //     }
        // };

        // document.addEventListener("visibilitychange", handleVisibilityChange);

        // return () => {
        //     document.removeEventListener("visibilitychange", handleVisibilityChange);
        // };
    }, []);
    
   
    const getbranchid=(branchid)=> {
        setBranchId( branchid )
    }

    const getNotificationCount=()=>{
        let formData = new FormData();
        let Query = `SLCI_Web_Proc_User_Notification_GetCount @UserID='{0}',@ClientID='{1}'`;
        let queryArr = [`${userid}`, `${clientid}`]
        let formattedString = StringFormatter(Query, queryArr)
        console.log(Query);
        formData.append("Query", formattedString);
        Axios({
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            // url: fetchRsUrl,
            data: formData
        }).then(res => {
            console.log(res.data)
            if (res.data[''].length > 0 && res.data[''] != null) {
                setNotificationCount(res.data[''][0].Notifications)
            }
        });
    }
    const closeNotificationDiv=(value)=> {
        setIsBellClicked(value)
    }
    
           let sideBarDetails = [
                { name: 'Home', icon: Home, targetPage: 'HomePage', group: 'top' },
                { name: 'Visitor logs', icon: Reports, targetPage: 'Visitorlogs', group: 'top' },
                { name: 'Entry Form', icon: DocumentStack, targetPage: 'Entryform', group: 'top' },
                // { name: 'Logout', icon: Logout, targetPage: 'Logout', group: 'top' },
            ]
        return (
            <>
                <div className="container-fluid" style={{ padding: "0px", overflow: "hidden" }}>
                    <div className="Dashboard_header" >
                        <div className="left_bar">
                            <img src={menuBtn} onClick={() => showWidth == "0px" ? showSidebar() : hideSidebar()} />
                        </div>
                        <div className="logo">
                            <img src={'https://vrms.hexbss.xyz/web/entryform/images/vmslogo final.png'} alt="hexbis" />                        
                        </div>
                        <div className="ActiveIconBox">
                            {navIcon !=="" ? <img src={navIcon} style={{ filter: Tint("#ffffff") }} />  : <img src={Home} style={{ filter: Tint("#ffffff") }} /> }                         
                            <span>{activeLink}</span>
                        </div>
                        <div className='button'>
                            <div className='qrcode' >
                                <button onClick={(e) => handleQRcode(e)} style={{ color: "#ffffff", flexDirection: "row", justifyContent: "right", background: "" }} className="btn" type="Qrcode" ><i className="fa-solid fa-qrcode"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="mainBox_Dashboard">
                        <div className="Dashboard_sidebar" style={{ width: showWidth, transition: "0.5s all" }}>
                            <div className="sidebar_list">
                                {sideBarDetails.map((s, index) => {
                                    return (
                                        <>{
                                                index != 0 && s.group !== sideBarDetails[index - 1].group ?
                                                    <>
                                                        <h5 style={{ fontWeight: '500', fontSize: '13px', paddingLeft: "30px", color: "rgba(0,0,0,0.7)", paddingTop: "10px", borderTop: "1px solid rgba(0,0,0,0.1)", width: "100%" }}>{s.group}</h5>
                                                    </>
                                                    : null}{
                                                s.name != "Entry Form" ?
                                                <NavLink
                                                key={index}
        
                                                to={`/${s.targetPage}`}
                                                className={({ isActive }) => `text-color-data tilebox ${isActive ? 'active' : ''}`}
                                                onClick={() =>{setActiveLink(s.targetPage) 
                                                    addActiveClass(s.name, s.icon)
                                                    // setContentBox(s.name, s.targetPage)
                                                }
                                                }
                                            >
                                                <div className="icon">
                                                    {console.log(activeLink === s.name,"activedata")}
                                                    <img 
                                                        src={s.icon} 
                                                        alt={s.name} 
                                                        style={{ filter: activeLink === s.name ? 'brightness(0) invert(1)' : '' }} 
                                                    />
                                                </div>
                                                <div className="desc">
                                                    <span>{s.name}</span>
                                                </div>
                                            </NavLink>:
                                                
                                                    
                                                <a
                                                href={`/${s.targetPage}?${Aesencryption(`orgdbname=${sessionStorage.getItem('orgdbname')}&apipath=${sessionStorage.getItem('Apipathurl')}`)}`}
                                                target="_blank"
                                                style={{ textDecoration: "none", color: "#000" }}
                                              >
                                                        <div className={"tilebox" + (s.name == activeLink ? " active" : "")}>
                                                            <div className="icon">
                                                                <img src={s.icon} style={{ filter: s.name == activeLink ? '#ca0903' : "" }} />
                                                            </div>
                                                            <div className="desc">
                                                                <span>{s.name}</span>
                                                            </div>
                                                        </div>
                                                    </a>}</>)})}

                                                    <NavLink
                                               
                                                className={({ isActive }) => `text-color-data tilebox `}
                                                onClick={()=>
                                                    
                                                    confirmBox()
                                                }
                                            >
                                                <div className="icon">
                                                  
                                                    <img 
                                                        src={Logout} 
                                                        alt="Logout" 
                                                         
                                                    />
                                                </div>
                                                <div className="desc">
                                                    <span>Logout</span>
                                                </div>
                                            </NavLink>
                            </div>
                        </div>
                        <div className="Dashboard_content" id="contentBox" style={{ width: `calc(100% - ${showWidth})`, transition: "0.5s all" }}>
                        <Outlet/>
                        </div>       
                    </div>
                    <div className={contentBoxClass} style={{ paddingRight: "0px", paddingLeft: "0px", backgroundColor: "#e5eaf3", marginTop: "-18px" }}>
                        <div className='container-fluid' id="contentBox" style={{ padding: "0px", paddingTop: "10px", marginTop: "10vh" }}>
                        </div>
                    </div>
                </div>
                <Modal id="ModalisOpen" class="fade" style={{ backgroundColor: 'rgba(0,0,0,0.6)', display: "20px" }} centered show={isModalOpen} onHide={closemodal}>
                    <div className="flex-Container" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px" }} >
                        <h2 style={{ fontWeight: "500", fontSize: "20px", color: "black" }}>Entry Form QR</h2>
                        <div>
                            <Pdf targetRef={QRcoderef} filename="code-example.pdf">
                                {({ toPdf }) => <button style={{ fontSize: "20px", color: "black", border: "none", background: "transparent" }} id="print"
                                    onClick={toPdf}><i class="fa fa-print fa-1x" aria-hidden="true" /></button>}
                            </Pdf>
                            <button type="button" onClick={(e) => closemodal(e)} style={{ border: "none", fontSize: "20px", position: "relative", top: "-5px" }} class="btn" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times fa-1x" aria-hidden="true"></i></button>
                        </div>
                    </div>
                    <Modal.Body>
                        <div ref={QRcoderef} style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
                            <QRCode
                                level="Q"
                                size={150}
                                value={`${sessionStorage.getItem("url")}Entryform?${Aesencryption(`orgdbname=${sessionStorage.getItem('orgdbname')}&apipath=${sessionStorage.getItem('Apipathurl')}`)}`}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal id="newModalisOpen" class="fade" style={{ backgroundColor: 'rgba(0,0,0,0.6)', display: "20px" }} centered show={isnewModalOpen} onHide={() => setisnewModalOpen(false)}>
                    <div className="flex-Container" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px" }} >
                        <h2 style={{ fontWeight: "500", fontSize: "20px", color: "black" }}>Exit Form QR</h2>
                        <div>
                            <button type="button" onClick={(e) => newclosemodal(e)} style={{ border: "none", fontSize: "20px", position: "relative", top: "-5px" }} class="btn" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times fa-1x" aria-hidden="true"></i></button>
                        </div>
                    </div>
                    <Modal.Body>
                        <QrReader
                            onResult={(result, error) => {
                                if (!!result) {
                                    setData(result?.text)
                                    // setState({ openscannermodal: false })
                                    console.log(data)
                                    setTimeout(() => {
                                        <a href={`${sessionStorage.getItem("url")}`}
                                            target="blank"
                                            style={{ minHeight: "48px" }}>
                                        </a>
                                        // getqrdata()
                                    }, 100);}
                            }}style={{ width: '10%' }}constraints={{facingMode: 'environment'}}/>
                    </Modal.Body>
                </Modal>
            </>
        )
    }




