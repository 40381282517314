import React, { useState, useEffect } from "react";
import './Login.css'
import { Input, Password } from '../../utility/HexInput/InputBox'
import Button from '../../utility/HexButton/button'
import Swal from "sweetalert2";
import Hexapi from '../../utility/HexAPI/Hexapi2'
import Support from './Support'
import { Modal } from 'react-bootstrap'
// import Logo from './images/VMSTXT copy.png'
import Logo from '../../assets/vmslogo final.png'
import vmslogo from '../../assets/VMSTXT copy.png'
import hexlogo from '../../assets/HEX Logo.png'
import { useNavigate } from "react-router";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../redux/features/apislice";
import { loginfailure, loginstart, loginsuccess } from "../../redux/features/authslice";

// sessionStorage.setItem("Apipathurl", "https://web.lawcrux.com/");

export default function Login() {

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isOpenSupport, setisOpenSupport] = useState(false);
  const [userlabelcolor, setuserlabelcolor] = useState("#7c3b7c")
  const [passlabelcolor, setpasslabelcolor] = useState("#7c3b7c")
  const [username, setusername] = useState("")
  const [password, setpassword] = useState("")
  const [data,setdata]=useState("")
  const [isModalOpen, setisModalOpen] = useState(false)
  const navigate=useNavigate()
  const generateUniqueId = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const clientdetails = localStorage.getItem('auth_lawcrux_id') != undefined ? localStorage.getItem('auth_lawcrux_id') : generateUniqueId(10)

  const handleforget = (event) => {
    console.log(event)
    setisOpenSupport(true)
  }
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.apislice.data); 
  
  console.log(selector.Apipathurl, "selector");
  
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);
  
  
  useEffect(() => {
    setdata(selector.Apipathurl);
  }, [selector.Apipathurl]); 
  

  const setSessions = (str) => {
    console.log(str)
    str = str.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(' ', '')
    while (str.toLowerCase().includes('sessionstorage.setitem')) {
      let key = str.substring(str.indexOf('("') + 2, str.indexOf('",'))
      let value = str.substring(str.indexOf(',') + 2, str.indexOf('")'))
      sessionStorage.setItem(key, value);
      str = str.replace(`sessionStorage.setItem("${key}","${value}");`, '')
      console.log(str,"str")
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(username)
    console.log(password)

    if (username != "") {
      if (password != "") {
        // console.log(generateUniqueId(10))
        let obj = {
          url: `${data}SuccessfullyV2.php`,
          username: `${username}`,
          password: `${password}`,
          clientdetails: `${clientdetails}`
        }
        // dispatch(loginstart());
        Hexapi(obj).then(resp => {
          console.log(resp,"resp")
          if (resp[''][0]['command'] == 1) {
            setSessions(resp[0])
            // dispatch(loginsuccess(resp[0]));
            console.log(resp)
            navigate("/Homepage")
          } else if (resp[''][0]['command'] == 2) {
            setSessions(resp[0])
            setisModalOpen(true)
          } else {
            Swal.fire({
              html: resp[''][0]['msg'],
              confirmButtonColor: '#5d176cf2'
            })
          }
        }).catch((err)=>{
          // dispatch(loginfailure("Login failed! Please try again.")); 
          Swal.fire({
            text: "Login failed! Please try again.",
            confirmButtonColor: '#5d176cf2'
          })
        })
      } else {
        Swal.fire({
          text: "Enter your Password",
          confirmButtonColor: '#5d176cf2'
        })
      }
    } else {
      Swal.fire({
        text: "Enter your Username",
        confirmButtonColor: '#5d176cf2'
      })
    }
  };

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <Input
            labelStyle={{ fontWeight: "600", fontSize: "18px", color: `${userlabelcolor}` }}
            label="Username"
            type="text"
            className="form-control mb-5"
            name="username"
            value={username}
            inline={false}
            onChange={(event) => setusername(event.target.value)}
          />

        </div>
        <div className="input-container">
          <Input
            labelStyle={{ fontWeight: "600", fontSize: "18px", color: `${passlabelcolor}` }}
            label="Password"
            type="password"
            className="form-control"
            value={password}
            name="pass"
            inline={false}
            onChange={(event) => setpassword(event.target.value)}
          />
        </div>
       <center><div className="button-container">

<Button buttonTitle="Login" style={{backgroundColor:"#9987aa"}} />
</div></center> 
      </form>

    </div>
  );
  const logoutFromOtherDevices = () => {

    let obj = {
      query: `[dbo].[Lawcrux_Web_Proc_User_Update_ClientDetails]
      @UserID ='{0}',
      @Details='{1}'`,
      queryArr: [`${sessionStorage.getItem('userid')}`, `${clientdetails}`]
    }
    Hexapi(obj).then(resp => {
      console.log(resp)
      
    })
  }

  return (
    <>
      <div className="app" >
        <div className="appflex">
          <div className="_Lloginbox">
            <img src={Logo} alt="" />
              <img src={vmslogo} alt="" />
            <div className="login-form" >
              {isSubmitted ? <div>You can achive your goal</div> : renderForm}
              
            </div>     
 </div>
 
 
          <Modal
            id="modalcss"
            show={isModalOpen}
            onHide={() => setisModalOpen(false)}
            style={{ backgroundColor: "rgba(0,0,0,0.9)" }}
            centered
          >
            <Modal.Body >
              <div className="_cmd2Modal">
                <span onClick={() => logoutFromOtherDevices()}>
                  Logout from other devices
                </span>
                <span onClick={() => setisModalOpen(false)}>
                  Cancel
                </span>
              </div>

            </Modal.Body>

          </Modal>

          {
            isOpenSupport &&
            <Support
              openModal={isOpenSupport}
              closeModal={() => setisOpenSupport(false)}
              user={username}
            />
          }
              <div className="hexlogo">
        <p>Designed and Powered by</p>
       <figure className="hexlogo-text">
        <img src={hexlogo} alt=""/> 
        </figure>
          </div> 
        </div>
      </div>
    </>
  )
};