import { configureStore } from "@reduxjs/toolkit";
import apidata from './features/apislice'
import authdata from './features/authslice'

export const store=configureStore({
    reducer:{
        apislice:apidata,
        authslice:authdata
    }
})
