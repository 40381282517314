import React, { Component } from 'react';
import { Input} from '../../utility/HexInput/InputBox';
import Select from '../../utility/select';
import Hexapi from '../../utility/HexAPI/Hexapi'
import Hexapiotp from '../../utility/HexAPI/Hexapiotp'
import { Aesdecryption} from '../../utility/Aes/Aes'
import Swal from "sweetalert2";
import {  Spinner } from 'react-bootstrap';
import Urlencoder from '../../utility/Urlencoder';
import Setimage from '../../utility/Setimage/setimage'
import { Modal } from 'react-bootstrap';
import { TimeFormatterV2 } from "../../utility/newTimeformatter/TimeFormatter";
import TimeFormatter from '../../utility/newTimeformatter/TimeFormatter';
import Paper from './inputotp';
import './EntryForm2.css'

class Entryfrom2 extends Component {
    constructor() {
        super();
        const decodeUrl = (data) => {
            console.log(data,"data")
            if (Aesdecryption(data.split('?')[1]) !== "") {
                console.log(data.split('?'),"data111111111111111111")
                let enctext = data.split('?')[1]
                let dectext = `?${btoa(Aesdecryption(enctext))}`
                console.log(Urlencoder(data.split('?')[0] + '/' + dectext),"data")
                return Urlencoder(data.split('?')[0] + '/' + dectext)
            } else {
                return Urlencoder(data)
            }
        }

        this.state = {
            data: decodeUrl(window.location.href),
            organisationname: "",
            date: new Date(),
            alertdata: "",
            visitortypeid: "",
            visitortype: [],
            visitortypename: "",
            personid: "",
            recid: "",
            person: [],
            personname: "",
            otpverification: [],
            otp: "",
            otpverify:"",
            purposename: "",
            purpose: [],
            purposeid: "",
            firstname: "",
            lastname: "",
            contactnumber: "",
            vistorcount: "",
            employeename: "",
            reason: "",
            isApiComplete: false,
            isDataAvailable: false,
            isModalOpen: false,
            companyname: "",
            display: false,
            Isthankscomponent: false,
            saveimage: [],
            isbuttonshow: true,
            requiredate1: "",
            persontomeetconfig: "",
            approvalstatusconfig:"",
            isdisabled:true
            // fetchrsurl: `${sessionStorage.getItem('Apipathurl')}`,
            // value: `${sessionStorage.getItem("url")}Approvalform?${Aesencryption(`orgdbname=${sessionStorage.getItem('orgdbname') }&visitorid=${this.state.recid}`)}`
        };
        this.storedate = this.storedate.bind(this);
        this.storeorganisationname = this.storeorganisationname.bind(this);
        this.storefirstname = this.storefirstname.bind(this);
        this.storelastname = this.storelastname.bind(this);
        this.storecontactnumber = this.storecontactnumber.bind(this);
        this.storevist = this.storevist.bind(this);
        this.storeemployeename = this.storeemployeename.bind(this);
        this.storereason = this.storereason.bind(this);
        this.storecompanyname = this.storecompanyname.bind(this);
        this.storevisitortype = this.storevisitortype.bind(this);
        this.storeotpverification = this.storeotpverification.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlevisitorid = this.handlevisitorid.bind(this);
        this.handleemp = this.handleemp.bind(this);
        this.handlesearch = this.handlesearch.bind(this);
        this.handleModal = this.handleModal.bind(this);


    }

    componentDidMount() {
        sessionStorage.setItem('Apipathurl', `${this.state.data.apipath}`)
        console.log(this.state.data.apipath)

        this.setState({ isApiComplete: false, isDataAvailable: false })

        let obj = {
            'query': `[dbo].[VRApp_Web_Proc_EntryForm_FormGetDataV2]`,
            'orgdbname': `${this.state.data.orgdbname}`,
            'queryArr': []
        }
        Hexapi(obj).then(resp => {
            console.log(resp,"orggetdata")
            console.log(resp["orgconfigs"][0]['persontomeetconfig'])
            console.log(resp["orgconfigs"][0]['approvalstatusconfig'])
            console.log(resp['otpverification'][0]['otpverification'])
            this.setState({ otpverify: resp['otpverification'][0]['otpverification'] })
            this.setState({ persontomeetconfig: resp["orgconfigs"][0]['persontomeetconfig'] })
            this.setState({ approvalstatusconfig: resp["orgconfigs"][0]['approvalstatusconfig'] })
            console.log(this.state.otp)
            console.log(this.state.persontomeetconfig)
            if (resp.visitortype != undefined && resp.purpose != undefined && resp[''][0]['orgname'] != undefined) {
                // if (resp.visitortype != undefined && resp.purpose != undefined && resp[''][0]['orgname'] != undefined) {
                this.setState({ visitortype: resp.visitortype, person: resp.person, purpose: resp.purpose, organisationname: resp[''][0]['orgname'], isDataAvailable: true })
                // this.setState({ visitortype: resp.visitortype, purpose: resp.purpose, organisationname: resp[''][0]['orgname'], isDataAvailable: true })
            }

            else {

                this.setState({ isDataAvailable: false })
            }


        }).then(resp => {
            this.setState({ isApiComplete: true })
        })

        this.storedate()
    }

    handlesearch(e) {
        console.log(this.state.contactnumber)
        if (e.target.value.length == 10) {
            
            let obj = {
                'query': `[dbo].[VRApp_Web_Proc_EntryForm_FetchData] @ContactNumber='{0}'`,
                'orgdbname': `${this.state.data.orgdbname}`,
                'queryArr': [
                    `${this.state.contactnumber}`,
                ]
            }
            Hexapi(obj).then(resp => {
                // console.log(resp)
                if (
                    this.state.contactnumber.length == 10 || !this.state.contactnumber.length > 0
                ) {
                    // Swal.fire({

                    //     // title: 'Oops...',  
                    //     text: 'Enter Contact Number ',
                    // })
                }

                this.setState({ firstname: resp[""][0]['firstname'] })
                this.setState({ lastname: resp[""][0]['lastname'] })
                this.setState({ companyname: resp[""][0]['companyname'] })
                this.setState({isdisabled:false})
            }).catch(err => { console.error(err) })
        }

    }

  async handleSubmit(otp) {
        console.log(otp)
        let optveri=otp
        try {
           
            const  obj={
             query:`[dbo].[VRApp_Web_Proc_EntryForm_VerifyOTP] @OTP='{0}',@ContactNumber='{1}'`,
             queryArr:[`${optveri}`,`${this.state.contactnumber}`]
             }  
             const getotp= await Hexapi(obj)
             console.log(getotp,"getotp")
             if(getotp[""][0].command ===1){
                let obj = {
                    'query': `  [dbo].[VRApp_Web_Proc_EntryForm_SubmitV4]
                                                            @FirstName='{0}',
                                                            @LastName='{1}',
                                                            @ContactNumber='{2}',
                                                            @VisitorCount='{3}', 
                                                            @PersonToMeet='{4}',
                                                            @VisitorTypeID='{5}', 
                                                            @PurposeID='{6}', 
                                                            @CompanyName='{7}',
                                                            @Photo=$%$File$%$, 
                                                            @PersonToMeetConfig='{9}'`,
        
                    'orgdbname': `${this.state.data.orgdbname}`,
                    'queryArr': [
                        `${this.state.firstname}`,
                        `${this.state.lastname}`,
                        `${this.state.contactnumber}`,
                        `${this.state.vistorcount}`,
                        `${this.state.employeename}`,
                        `${this.state.visitortypeid}`,
                        `${this.state.purposeid}`,
                        `${this.state.companyname}`,
                        `${this.state.saveimage}`,
                        `${this.state.persontomeetconfig}`,
                    ],
                        
                    'file': this.state.saveimage,
                }
                Hexapi(obj).then(resp => {
                    console.log(resp)
                    if (resp[''][0]['command'] == 1) {
                   this.setState({isModalOpen:false})
                        Swal.fire({
                            text: `${resp[""][0]["msg"]} `,
                            confirmButtonColor: '#9987aa'
                        }).then(function () {
                            window.location.reload();
                        }
                        )
                    }
                    else {
                        Swal.fire({
        
                            // title: 'Oops...',  
                            text: `${resp[""][0]["msg"]}`,
                            confirmButtonColor: '#9987aa'
                        }).then(function () {
                            window.location.reload();
                        }
                        )
                    }
        
                }).catch(err => { console.error(err) })
        
             } else if(getotp[""][0].command===0){
                
                Swal.fire({
                    text:getotp[""][0].msg,
                    title:"Oops"
                })
             }
        } catch (error) {
            
        }
  



}

    closemodal() {
        this.setState({ isModalOpen: false })
    }
    handleotp(otp) {
        document.getElementById("removebtn").style.display = "none"
        if (this.state.otp = 1) {
            this.setState({ isbuttonshow: true })
        }
        else {
            this.setState({ isbuttonshow: false })
        }
        console.log("otp resend")

        let obj = {
            'phoneno': `${this.state.contactnumber}`,
            'orgdbname': `${this.state.data.orgdbname}`,
        }

        Hexapiotp(obj).then(resp => {
            console.log(resp)
            if (resp == 1) {
                this.setState({
                    isModalOpen: true
                })
            }
            else {
                Swal.fire({
                    // title: 'Oops...',  
                    text: 'Invalid otp ! ',
                })
            }
        })
    }

    handleModal(e) {
        console.log(this.state.employeename)
        var persontomeet=this.state.persontomeetconfig=="0"?this.state.employeename : this.state.personid

        if (this.state.saveimage != "") {
            if (
                this.state.contactnumber.length == 10 ||
                !this.state.contactnumber.length > 0
            )
                if (this.state.contactnumber != "") {
                    if (this.state.companyname != "") {
                        if (this.state.vistorcount != "") {
                            if (this.state.firstname != "") {
                                if (this.state.lastname != "") {
                                    if (this.state.visitortypeid != "") {
                                        if (persontomeet != "") {
                                            if (this.state.purposeid != "") {
                                                if (this.state.otpverify != 1) {
                                                    let obj = {
                                                    'query': `  [dbo].[VRApp_Web_Proc_EntryForm_SubmitV4]
                                                    @FirstName='{0}',
                                                    @LastName='{1}',
                                                    @ContactNumber='{2}',
                                                    @VisitorCount='{3}', 
                                                    @PersonToMeet='{4}',
                                                    @VisitorTypeID='{5}', 
                                                    @PurposeID='{6}', 
                                                    @CompanyName='{7}', 
                                                    @Photo=$%$File$%$, 
                                                    @PersonToMeetConfig='{9}'`,
                                                    'orgdbname': `${this.state.data.orgdbname}`,
                                                        'queryArr': [
                                                            `${this.state.firstname}`,
                                                            `${this.state.lastname}`,
                                                            `${this.state.contactnumber}`,
                                                            `${this.state.vistorcount}`,
                                                            `${persontomeet}`,
                                                            `${this.state.visitortypeid}`,
                                                            `${this.state.purposeid}`,
                                                            `${this.state.companyname}`,
                                                            `${this.state.saveimage}`,
                                                            `${this.state.persontomeetconfig}`,
                                                        ],
                                                            'file': this.state.saveimage,
                                                    }
                                                Hexapi(obj).then(resp => {
                                                    console.log(resp)
                                                    // console.log(resp[""][0]["command"])
                                                    if (resp[''][0]['command'] == 1) {
                                                        this.setState({ recid: resp["visitor"][0]['visitorid'] })
                                                        // setTimeout(() => {
                                                        // console.log(`${sessionStorage.getItem("url")}Approvalform?${Aesencryption(`orgdbname=${sessionStorage.getItem('orgdbname')}&visitorid=${this.state.recid}`)}`)
                                                        // }, 2000);
                                                        //MOEAL FALSE
                                                        Swal.fire({
                                                            text: `${resp[""][0]["msg"]}`,
                                                            confirmButtonColor: '#9987aa'

                                                        }).then(function () {
                                                            window.location.reload();
                                                        }
                                                        )
                                                    }
                                                    else {
                                                        Swal.fire({

                                                            // title: 'Oops...',  
                                                            text: `${resp[""][0]["msg"]}`,
                                                            confirmButtonColor: '#9987aa'
                                                        })
                                                    }

                                                }).catch(err => { console.error(err) })


                                            } else {
                                                this.setState({ isModalOpen: true })
                                                let obj = {
                                                    'phoneno': `${this.state.contactnumber}`,
                                                    'orgdbname': `${this.state.data.orgdbname}`,
                                                }

                                                Hexapiotp(obj).then(resp => {
                                                    console.log(resp,"Responseotp")
                                                    if (resp == 1) {
                                                        this.setState({ isModalOpen: true })
                                                    }
                                                    else {
                                                        Swal.fire({

                                                            // title: 'Oops...',  
                                                            text: 'Invalid otp ! ',
                                                        })

                                                    }
                                                })

                                            }
                                        } else {
                                            Swal.fire({

                                                // title: 'Oops...',
                                                text: 'Purpose not Selected !',
                                                confirmButtonColor: '#9987aa'

                                            });

                                        }

                                    } else {
                                        Swal.fire({

                                            // title: 'Oops...',
                                            text: 'Person to Meet not Selected !',
                                            confirmButtonColor: '#9987aa'

                                        });
                                    }

                                } else {
                                    {
                                        Swal.fire({
                                            // title: 'Oops...',
                                            text: 'Visitor type not filled !',
                                            confirmButtonColor: '#9987aa'
                                        });
                                    }
                                }

                            } else {
                                {
                                    Swal.fire({
                                        // title: 'Oops...',
                                        text: 'last Name not  filled!',
                                        confirmButtonColor: '#9987aa'
                                    });
                                }
                            }
                        } else {
                            {
                                Swal.fire({
                                    // title: 'Oops...',
                                    text: 'First Name not filled!',
                                    confirmButtonColor: '#9987aa'
                                });
                            }
                        }
                    } else {
                        Swal.fire({
                            // title: 'Oops...',
                            text: 'Visitor count not filled!',
                            confirmButtonColor: '#9987aa'
                        });

                    }
                } else {
                    {
                        Swal.fire({
                            // title: 'Oops...',
                            text: 'Company name not filled !',
                            confirmButtonColor: '#9987aa'
                        });
                    }
                }
        } else {
            {
                Swal.fire({
                    // title: 'Oops...',
                    text: 'Contact number not filled !',
                    confirmButtonColor: '#9987aa'
                });
            }
        }
    } else {
    {
        Swal.fire({
            // title: 'Oops...',
            text: 'Image not Selected !',
            confirmButtonColor: '#9987aa'
        });
    }
}


<Spinner animation="border" variant="dark" />
    }
storevisitortype(e) {
    this.setState({ visitortypename: e.target.value })
    console.log(this.state.visitortypename)
}

storeotpverification(e) {
    this.setState({ otpverify: e.target.value })
}

storedate() {
    let requiredate1 = TimeFormatterV2(new Date(), 'fulldate', 'YYYY-MM-DD HH:mm:ss');
    var transformdate = requiredate1.split(".")[0];
    // console.log(date);
    var exitdate = TimeFormatter(transformdate);
    // console.log(exitdate);
    var newReverseDate =
        exitdate.ShiftTime +
        "," +
        exitdate.userSplitedDate[2] +
        " " +
        exitdate.ShiftMonth +
        "`" +
        exitdate.CurYear.toString().slice(-2);
    console.log(newReverseDate);
    this.setState({ date: newReverseDate })

    // return newReverseDate;


}
storecompanyname(e) {
    this.setState({ companyname: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })
}

storeorganisationname(e) {
    // console.log(e.target.value);
    this.setState({ organisationname: e.target.value })
}
storefirstname(e) {
    // if (e.target.value === "" || re.test(e.target.value)) {
    //     this.setState({ firstname: e.target.value });
    // }
    // console.log(e.target.value);
    this.setState({ firstname: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })
}
storelastname(e) {
    // console.log(e.target.value);
    this.setState({ lastname: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })
}
storecontactnumber(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ contactnumber: e.target.value });
    }
    setTimeout(() => {
        if (e.target.value.length == 10) {
            this.setState({isdisabled:false})
            this.handlesearch(e)
        }
    }, 200);
    // console.log(e.target.value.length);
}
storevist(e) {
    // console.log(e.target.value);
    const re = /^[0-9]/;
    if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ vistorcount: e.target.value })
    }
}
storeemployeename(e) {
    console.log(e.target.value);
    this.setState({ employeename: e.target.value })

}
storereason(e) {
    // console.log(e.target.value);
    this.setState({ reason: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })

}
handlepurposeid(e) {
    console.log(e.target.value);
    this.setState({ purposeid: e.target.value })
    console.log(this.state.display)
    if (e.target.value == '3') {
        this.setState({ display: true });
    }
    else {
        this.setState({ display: false });
    }
}
handlevisitorid(e) {
    console.log(e.target.value);
    this.setState({ visitortypeid: e.target.value })
}

handleemp(e) {
    console.log(e.target.value);
    this.setState({ personid: e.target.value })
}
callbinaryfile(e) {
    console.log(e)
    this.setState({ saveimage: e })
}

render() {
    console.log(this.state.data,"data765446")
    console.log(this.state.otpverify,"otpverify")
    return (
        <>
           {this.state.otpverify == 1 && this.state.isModalOpen && 
                    <Modal
                        id="modalcss"
                        show={this.state.isModalOpen}
                        onHide={() => this.closemodal()}
                        style={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                        centered
                    >
                        {/* <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body>
                            <Paper
                                id="removebtn"
                                getcounter=""
                                dbname={this.state.data.orgdbname}
                                number={this.state.contactnumber}
                                callback={(otp) => this.handleSubmit(otp)}
                                callotp={(otp) => this.handleotp(otp)} />
                        </Modal.Body>

                    </Modal>
           } 
            
            <div className='container data-entry'>
                <div className='row cls-row'>
                <center><p className='text-entry'>Entry Form</p></center>
                <center><p className='text-org'>{this.state.organisationname}</p></center>
                </div>
                
                <div className="row ">
                    <div className='col-md-4   '>
                        <Setimage callback={(e) => this.callbinaryfile(e)} attachmentbutton={false} camerabutton={true} showphoto={true} />
                    </div>
                    {
                        this.state.isApiComplete ?
                            this.state.isDataAvailable ?
                                <div className='col-md-8 box-data'>
                                    <div className='row'>
                                        <div className='col-md-6'>

                                            <Input
                                                label={"Time & Date"}
                                                labelStyle={{ color: "black", fontWeight: "500" }}
                                                inputStyle={{ color: "blue", paddingLeft: "10px", margin: "auto" }}
                                                // inline={true}
                                                disabled={true}
                                                value={this.state.date}
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <Input
                                                label={"Contact Number"}
                                                placeholder={"Enter Contact Number"}
                                                labelStyle={{ color: "black", fontWeight: "500" }}
                                                inputStyle={{ color: "blue", paddingLeft: "10px", border: "2px", margin: "auto" }}
                                                value={this.state.contactnumber}
                                                required={true}
                                                wordboundation={{ limit: 10, id: "descriptionInput" }}
                                                onChange={(e) => this.storecontactnumber(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <Input
                                                label={"Company Name"}
                                                placeholder={"Enter Company Name"}
                                                labelStyle={{ color: "black", fontWeight: "500" }}
                                                inputStyle={{ color: "blue", paddingLeft: "10px", border: "2px", margin: "auto" }}
                                                value={this.state.companyname}
                                                required={true}
                                                onChange={(e) => this.storecompanyname(e)}
                                                disabled={this.state.isdisabled}

                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <Input
                                                label={"First Name "}
                                                placeholder={"Enter First Name "}
                                                labelStyle={{ color: "black", fontWeight: "500" }}
                                                inputStyle={{ color: "blue", paddingLeft: "10px", border: "2px", margin: "auto" }}
                                                value={this.state.firstname}
                                                required={true}
                                                onChange={(e) => this.storefirstname(e)}
                                                disabled={this.state.isdisabled}
                                            />
                                        </div>
                                       
                                    </div>
                                    <div className='row'>


                                     
                                        <div className='col-md-6'>
                                            <Input
                                                label={"Last Name "}
                                                placeholder={"Enter Last Name"}
                                                labelStyle={{ color: "black", fontWeight: "500" }}
                                                inputStyle={{ color: "blue", paddingLeft: "10px", border: "2px", margin: "auto" }}
                                                value={this.state.lastname}
                                                required={true}
                                                onChange={(e) => this.storelastname(e)}
                                                disabled={this.state.isdisabled}
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <Input
                                                label={"No. of Vistor"}
                                                placeholder={"Enter Vistor Count"}
                                                // type={"number"}
                                                required={true}
                                                labelStyle={{ color: "black", fontWeight: "500" }}
                                                inputStyle={{ color: "blue", paddingLeft: "10px", border: "2px", margin: "auto" }}
                                                value={this.state.vistorcount}
                                                wordboundation={{ limit: 4, id: "count" }}
                                                onChange={(e) => this.storevist(e)}
                                                disabled={this.state.isdisabled}
                                            />
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <Select
                                                label={"Visitor type"}
                                                value={this.state.visitortypeid}
                                                data={this.state.visitortype}
                                                // inline={true}
                                                required={true}
                                                readOnly={true}
                                                defaultset={"--Select--"}
                                                labelStyle={{ color: "black", fontWeight: "500" }}
                                                inputStyle={{ color: "blue", paddingLeft: "10px", margin: "auto" }}
                                                onChange={(e) => this.handlevisitorid(e)}
                                                disabled={this.state.isdisabled}
                                            // disabled={true}

                                            />

                                        </div>
                                        <div className='col-md-6'>
                                            {
                                                this.state.persontomeetconfig == "0" ?
                                                    <Input
                                                        label={"Person to meet"}
                                                        placeholder={"Enter Person's Name"}
                                                        labelStyle={{ color: "black", fontWeight: "500" }}
                                                        inputStyle={{ color: "blue", paddingLeft: "10px", border: "2px", margin: "auto" }}
                                                        value={this.state.employeename}
                                                        required={true}
                                                        onChange={(e) => this.storeemployeename(e)}
                                                        disabled={this.state.isdisabled}
                                                    />
                                                    :
                                                    <Select
                                                        label={"Person to meet"}
                                                        value={this.state.personid}
                                                        data={this.state.person}
                                                        // inline={true}
                                                        required={true}
                                                        readOnly={true}
                                                        defaultset={"--Select--"}
                                                        labelStyle={{ color: "black", fontWeight: "500" }}
                                                        inputStyle={{ color: "blue", paddingLeft: "10px", margin: "auto" }}
                                                        onChange={(e) => this.handleemp(e)}
                                                        disabled={this.state.isdisabled}
                                                    // disabled={true}
                                                    />

                                            }

                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <Select
                                                label={"Purpose"}
                                                value={this.state.purposeid}
                                                data={this.state.purpose}
                                                // inline={true}
                                                readOnly={true}
                                                required={true}
                                                defaultset={"--Select--"}
                                                labelStyle={{ color: "black", fontWeight: "500" }}
                                                inputStyle={{ color: "blue", paddingLeft: "10px", margin: "auto" }}
                                                onChange={(e) => this.handlepurposeid(e)}
                                                disabled={this.state.isdisabled}
                                            />
                                        </div>
                                        {
                                            this.state.display ?
                                                <div className='col-md-6'>
                                                    <Input
                                                        label={"Reason"}
                                                        placeholder={"Enter your Reason"}
                                                        labelStyle={{ color: "black", fontWeight: "500" }}
                                                        inputStyle={{ color: "blue", paddingLeft: "10px", border: "2px", margin: "auto" }}
                                                        value={this.state.reason}
                                                        required={true}
                                                        onChange={(e) => this.storereason(e)}
                                                        disabled={this.state.isdisabled}
                                                    />
                                                </div>
                                                : null
                                        }
                                        <div className='submit mt-3' style={{ justifyContent: "center", display: "flex", marginTop: "20px" }}>
                                            <button onClick={(e) => this.handleModal(e)} style={{ color: 'white', fontWeight:"600", flexDirection: "row", justifyContent: "right", backgroundColor: '#5d176cf2' }} class="btn " type="submit" >Submit</button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "50vh",
                                    }}
                                >
                                    <h3 style={{ fontFamily: "sans-serif", color: "var(--Record)" }}>No Records!</h3>
                                </div>
                            :
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "50vh",
                                    alignItems: "center"
                                }}
                            >
                                <Spinner animation="border" variant="dark" />
                            </div>

                    }
                </div>
                {/* </div> */}
            </div>
        </>
    )
}
}
export default Entryfrom2;
















