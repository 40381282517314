import axios from 'axios'
import { StringFormatter } from './StringFormatter';
import { Aesdecryption, Aesencryption } from '../Aes/Aes'

const Hexapi = (props) => new Promise((resolve, reject) => {
    let fd = new FormData();
    if (props.query != undefined && props.queryArr != undefined) {
        var formattedQueryString = StringFormatter(props.query, props.queryArr)
        console.log(formattedQueryString)
        fd.append(Aesencryption("Query"), Aesencryption(formattedQueryString))
    }
    if (props.file != undefined) {
        fd.append(Aesencryption("file"), props.file)
    }
    if (props.orgdbname != undefined) {
        fd.append(Aesencryption('orgdbname'), Aesencryption(props.orgdbname))
    }
    // let url = `${sessionStorage.getItem('Apipathurl')}fetchrsdataV3.php`
    let url=''
    if (props.url != undefined) {
        url = props.url
    }
    if (props.username != undefined) {
        fd.append(Aesencryption('username'), Aesencryption(props.username))
    }
    if (props.password != undefined) {
        fd.append(Aesencryption('password'), Aesencryption(props.password))
    }
    
    if (props.clientdetails != undefined) {
        fd.append(Aesencryption('clientdetails'), Aesencryption(props.clientdetails))
    }
    // console.log(Aesencryption('clientdetails'));

    axios({
        mode: 'cors',
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        url: url,
        data: fd
    }).then(resp => {
        console.log(resp.data)
        let decrypted = Aesdecryption(resp.data)
        console.log(decrypted)
        if (JSON.parse(decrypted)) {
            resolve(JSON.parse(decrypted))
        } else {
            reject('not get any response')
        }
    }).catch(err => console.error(`axios error in query : ${props.query}`, err))
})

export default Hexapi;