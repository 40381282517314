import React, { useState, useEffect } from 'react';
import { Grid, Paper, Box, Avatar } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Hexapiotp from '../../utility/HexAPI/Hexapiotp';
import Swal from "sweetalert2";
import Button from '../../utility/HexButton/button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paperStyle: {
        padding: theme.spacing(3),
        width: '100%',
        maxWidth: 400,
        margin: 'auto',
        marginBottom: theme.spacing(10),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(5),
        },
        textAlign:"center"
    },
    avatarStyle: {
        backgroundColor: '#6d7f9f',
    },
    formHeader: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        '& h2': {
            color: 'red',
        },
    },
    otpBox: {
        marginTop: theme.spacing(2),
    
    },
    resendText: {
        fontWeight: 500,
        color: theme.palette.text.secondary,
        textAlign: 'center',
    },
    timer: {
        color: 'green',
        fontWeight: 'bold',
    },
}));

const Login = (props) => {
    const classes = useStyles();

    const [otp, setOtp] = useState("");
    const [counter, setCounter] = useState(60);
    const [showResendButton, setShowResendButton] = useState(false);

    const handleChange = (e) => {
        setOtp(e.target.value);
    };

    const handleOtp = () => {
        setShowResendButton(false); 
        setCounter(60);

        const obj = {
            'phoneno': props.number,
            'orgdbname': props.dbname,
        };

        Hexapiotp(obj).then(resp => {
            if (resp === 1) {
               
            } else {
                Swal.fire({
                    title: 'Oops...',
                    text: 'Invalid OTP!',
                });
            }
        });
    };

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        
        if (counter === 0) {
            setShowResendButton(true); 
        }

        return () => clearInterval(timer); 
    }, [counter]);

    const phone = props.number.slice(-4); 
    return (
      
           <div>
                <Grid align='center' className={classes.formHeader}>
                   <center><Avatar className={classes.avatarStyle}><LockOutlinedIcon /></Avatar></center> 
                    <h2>Verify</h2>
                    <Box color="black">
                        <span>Enter OTP sent to your mobile number XXX-XXX-{phone}</span>
                    </Box>
                </Grid>
                <center>  <Box  className={classes.otpBox}>
                <ValidatorForm onSubmit={() => props.callback(otp)}>
                    <TextValidator
                        label="Enter 6 Digit OTP"
                        onChange={handleChange}
                        variant="outlined"
                        inputProps={{ maxLength: 6 }}
                        name="otp"
                        size="small"
                        type="text"
                        fullWidth
                        validators={['required', 'isNumber', 'minStringLength:6', 'maxStringLength:6']}
                        errorMessages={['OTP is required', 'OTP must be a number', 'OTP must be 6 digits']}
                        value={otp}
                    />
                    <Button buttonTitle='Verify' className="mt-3" type="submit" />
                </ValidatorForm>
                </Box></center>
              


                <center><Box className={classes.otpBox}>
                    <span className={classes.resendText}>
                        Resend OTP in <span className={classes.timer}>00:{counter < 10 ? `0${counter}` : counter}</span>
                    </span>
                </Box></center>

                {showResendButton && (
                   <center><Button buttonTitle='Resend OTP' className="mt-3" onClick={handleOtp} /></center> 
                )}
          </div>
       
    );
};

export default Login;
