import React, { Component } from "react";
import { ModalHeader, Table } from "react-bootstrap";
import "./table.css";
import Hexapi from '../../utility/HexAPI/Hexapi';
import { Spinner } from 'react-bootstrap'
import TimeFormatter from '../../utility/newTimeformatter/TimeFormatter'
import Datatable from "./table/Datatable";
import InnerDatatable from "./table/InnerDatatable";
import { Modal } from "react-bootstrap";
import GetImage from "../../utility/GetImage/GetImage";
import CloseIcon from '@mui/icons-material/Close';


class Visitorlog extends Component {
    constructor() {
        super()
        this.state = {
            userid: sessionStorage.getItem('userid'),
            header: [],
            tableData: [],
            isApiComplete: false,
            isDataAvailable: false,
            isModalOpen: false,
            rowData: [],
            rowTabledata: [],
            showDatable: false,
        }
        // this.storeuserid = this.storeuserid.bind(this);
        this.fetchTableData = this.fetchTableData.bind(this);


    }
    // storeuserid(e) {
    //     console.log(e)
    //     this.setState({ userid: e.target.value })
    // }


    fetchTableData() {
        this.setState({ isApiComplete: false, isDataAvailable: false })
        let obj = {
            'query': `[dbo].[VRApp_Web_Proc_VisitorLogs_GetList]@UserID='{0}'`,
            'queryArr': [
                `${this.state.userid}`,
            ]
        }
        Hexapi(obj).then(resp => {

            console.log(resp)
            if (resp[''].length > 0 && resp[''] != null) {
                var keys = Object.keys(resp[''][0]);
                console.log(keys)
                this.setState({ tableData: resp[''], header: Object.keys(resp[''][0]), isDataAvailable: true })
            } else {
                this.setState({ isDataAvailable: false })
            }
            // console.log(resp[""][""][0])
            // this.setState(this.state.userid)
        }).then(resp => {
            this.setState({ isApiComplete: true })
        })

    }
    setDate(date) {
        if (date != undefined) {
            var transformdate = date.split(".")[0];
            console.log(date);
            var exitdate = TimeFormatter(transformdate);
            console.log(exitdate);
            var visitorinfo = this.state.visitorinfo
            console.log(visitorinfo)
            var newReverseDate =
                exitdate.ShiftTime +
                "," +
                exitdate.userSplitedDate[2] +
                " " +
                exitdate.ShiftMonth +
                "`" +
                exitdate.CurYear.toString().slice(-2);
            console.log(newReverseDate);
            //   this.setState({ visitorinfo.intime: newReverseDate })

            return newReverseDate;
        }
    }


    componentDidMount() {
        this.fetchTableData()
        // sessionStorage.setItem('apipathurl')
    }


    vistortableclick(e) {
        // console.warn(e)
        // let obj = {
        //     'query': `[dbo].[VRApp_Web_Proc_Visit_Details_GetList]@VisitorID='{0}'`,
        //     'queryArr': [`${e.visitorid}`]
        // }
        // Hexapi(obj).then(resp => {
        //     console.log(resp[''][0])
        //     console.log(resp[''].length)
        //     if (resp[''].length > 0) {
        //         this.setState({ rowTabledata: resp[''], showDatable: true }, () => {
        //             this.setState({ rowData: e, isModalOpen: true })

        //         })
        //     } else {
        //         this.setState({ showDatable: false }, () => {
        //             this.setState({ rowData: e, isModalOpen: true })

        //         })
        //     }
        // });
    }


    render() {




        return (
            <>

                <div className="container-fulid" style={{ padding: "20px", display: "fixed" }}>
                    <div className="row" >
                        <div className="col-md-12" >
                            {
                                this.state.isApiComplete ?
                                    this.state.isDataAvailable ?
                                        <div>
                                            <Datatable
                                                data={this.state.tableData}
                                                headStyle={{ textTransform: "Capitalize", backgroundColor:'white !important' }}
                                                heading="Visitor Log Table"
                                                isNavbar={true}
                                                pagination={{
                                                    rowsPerPage: 10,
                                                    rowsPerPageOptions: [10, 50, 100, { label: 'All', value: -1 }]
                                                }}
                                                onRowClick={(e) => this.vistortableclick(e)}

                                            />
                                        </div>
                                        :
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "50vh",
                                            }}
                                        >
                                            <h3 style={{ fontFamily: "sans-serif", color: "rgb(153, 135, 170)" }}>No Records!</h3>
                                        </div>


                                    :
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "100%",
                                            height: "50vh",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Spinner animation="border" variant="dark" />
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                <Modal id="ModalisOpen" size="lg" class="fade" style={{ backgroundColor: 'rgba(0,0,0,0.6)', display: "50px" }} centered show={this.state.isModalOpen} onHide={() => this.setState({ isModalOpen: false })}>
                    {/* <Modal.Header closeButton>
                    </Modal.Header> */}
                    <Modal.Body>
                        <div className="Container" style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                {/* <h2 style={{ fontWeight: "600", fontSize: "20px", color: "black" }} >Card View</h2> */}
                                <div>
                                    <CloseIcon style={{ cursor: 'pointer' }} onClick={() => { this.setState({ isModalOpen: false }) }} />
                                </div>
                            </div>
                            <div >
                                <button onClick={(e) => this.vistorexit(e)} style={{ fontSize: "20px", color: "red", border: "none", background: "transparent" }} type="Exit" ><i class="fa fa-sign-out " aria-hidden="true"></i></button>
                                <button type="button" onClick={(e) => this.closemodal(e)} style={{ marginLeft: "3px", top: "15px", border: "none" }} class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times " aria-hidden="true"></i></button>
                            </div>
                        </div>
                        <div>
                            <div className="print-container" >
                                <div className="modal-container" >
                                    {/* <span style={{ fontSize: "17px", color: "black", textAlignLast: "" }}></span> <span style={{ fontWeight: "700", fontSize: "20px" }}>{sessionStorage.getItem('orgname')}</span><br /> */}

                                    <div  >
                                        <div className="ImageContainer">

                                            <GetImage
                                                imgID={this.state.rowData.visitorid}
                                                recid={this.state.rowData.visitorid}
                                                getPhotoUrl={`${sessionStorage.getItem('Apipathurl')}getphotov2.php`}
                                                callback={this.callback}
                                            />

                                        </div>
                                        <div style={{ textAlign: 'center', marginBottom:'10px' }}>
                                            <span style={{fontWeight:'500'}}>Visitor Photo</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="ImageContainer">
                                            <GetImage
                                                imgID={this.state.rowData.visitorid}
                                                recid={this.state.rowData.visitorid}
                                                getPhotoUrl={`${sessionStorage.getItem('Apipathurl')}getdoc.php`}
                                                callback={this.callback}
                                            />
                                        </div>
                                        <div style={{ textAlign: 'center', marginBottom:'10px' }}>
                                            <span style={{fontWeight:'500'}}>Visitor Id</span>
                                        </div>
                                    </div>
                                    <div
                                    // style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '25px' }}
                                    >
                                        {/* <div> */}
                                        <span style={{ fontWeight: "700", color: "black", textAlignLast: "" }}>Firstname:-</span> <span style={{ fontWeight: "500" }}>{this.state.rowData.firstname}</span><br />
                                        <span style={{ fontWeight: "700", color: "black", textAlignLast: "" }}>Company Name:-</span> <span>{this.state.rowData.companyname}</span><br />
                                        <span style={{ fontWeight: "700", color: "black", textAlignLast: "" }}>Designation :-</span> <span>{this.state.rowData.designation}</span><br />
                                        {/* </div>
                                    <div> */}
                                        <span style={{ fontWeight: "700", color: "black", textAlignLast: "" }}>Lastname:-</span> <span style={{ fontWeight: "500" }}>{this.state.rowData.lastname}</span><br />
                                        <span style={{ fontWeight: "700", color: "black", textAlignLast: "" }}>Conatct Number:-</span> <span style={{ fontWeight: "500" }}>{this.state.rowData.contactnumber}</span><br />
                                        <span style={{ fontWeight: "700", color: "black", textAlignLast: "" }}>Email Id:-</span> <span style={{ fontWeight: "500" }}>{this.state.rowData.emailid}</span><br />

                                        {/* </div> */}

                                    </div>
                                    {/* <div  >
                                        <span style={{ fontWeight: "700", color: "black", textAlignLast: "" }}>Firstname:-</span> <span style={{ fontWeight: "500" }}>{this.state.rowData.firstname}</span><br />
                                        <span style={{ fontWeight: "700", color: "black", textAlignLast: "" }}>Lastname:-</span> <span style={{ fontWeight: "500" }}>{this.state.rowData.lastname}</span><br />
                                    </div> */}

                                </div>



                            </div>
                            <div style={{ marginTop: '20px' }}>
                                {
                                    this.state.showDatable ?
                                        <InnerDatatable
                                            data={this.state.rowTabledata}
                                            headStyle={{ textTransform: "Capitalize", backgroundColor: 'black', color: 'white' }}
                                            // isNavbar={true}
                                            pagination={{
                                                rowsPerPage: 10,
                                                rowsPerPageOptions: [10, 50, 100, { label: 'All', value: -1 }]
                                            }}
                                        // onRowClick={(e) => this.vistortableclick(e)}
                                        />
                                        :
                                        null
                                }

                            </div>
                        </div>
                    </Modal.Body>

                </Modal>

            </>
        );



    }

}

export default Visitorlog;






