import axios from 'axios';
import { StringFormatter } from './StringFormatter';
import { Aesdecryption, Aesencryption } from '../Aes/Aes';
let data=''
const getapi=async()=>{
    const api= await axios.get('/apiinfo.json')
    data=api.data.Apipathurl
    console.log(api,"apihex------------")
}
const Hexapi = async (props) => {
    await getapi()
  if(data !==""){
    try {
        const fd = new FormData();
        const formattedQueryString = StringFormatter(props.query, props.queryArr);
        console.log(formattedQueryString);
        fd.append(Aesencryption("Query"), Aesencryption(formattedQueryString));
        if (props.file !== undefined) {
            fd.append(Aesencryption("file"), props.file);
        }
        if (props.orgdbname !== undefined) {
            fd.append(Aesencryption('orgdbname'), Aesencryption(props.orgdbname));
        }
        const resp = await axios({
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            url: `${data}fetchrsdataV3.php`,
            data: fd
        });
        const decrypted = Aesdecryption(resp.data);
        const jsonData = JSON.parse(decrypted); 
        return jsonData;
    } catch (err) {
        console.error(`Error in Hexapi for query: ${props.query}`, err);
        throw new Error('Failed to fetch data'); 
    }
  }
};

export default Hexapi;