import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  data: [],
  loading: false,
  error: null,
};


export const fetchData = createAsyncThunk(
  'data/fetchData', 
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get('/apiinfo.json'); 
      console.log(response,"responseredux")
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message); 
    }
  }
);


const apireducer = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true; 
        state.error = null; 
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload; 
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false; 
        state.error = action.payload; 
      });
  },
});


export default apireducer.reducer;
