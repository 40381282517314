import React, { Component } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import './support.css'
import CloseIcon from '@mui/icons-material/Close';
import { Input, Password } from '../../utility/HexInput/InputBox'
import IconButton from '@mui/material/IconButton';
import Button from '../../utility/HexButton/button'
import Hexapi from '../../utility/HexAPI/Hexapi'
import Swal from 'sweetalert2';

export default class Support extends Component {
    constructor(props) {
        super()
        this.state = {
            showing: false, newpassword: "",
            isComponentRender: false, openInstantSupport: false, queryError: ''
        }
    }
    storepassword(e) {
        this.setState({ newpassword: e.target.value })
        console.log(this.state.newpassword)
    }
    componentDidMount() {
        this.setState({ isComponentRender: this.props.openModal })
    }
    showdiv() {
        // document.getElementById("hidediv").style.display="none";
        // document.getElementById("descrition").style.display="block";
        if (this.state.newpassword != "") {
            let obj = {
                'query': `[dbo].[Lawcrux_Web_Proc_PasswordChangeRequest_Submit]@Username='{0}',@NewPassword='{1}'`,
                'queryArr': [
                    `${this.props.user}`,
                    `${this.state.newpassword}`,
                ]
            }
            Hexapi(obj).then(resp => {
                console.log(resp)
                if (resp[''][0]['command'] == 0) {
                    Swal.fire({
                        text: `${resp[""][0]["msg"]}`,
                        confirmButtonColor: '#e1563f'
                    })
                }
                // console.log(this.props.username)
            })
        } else {
            Swal.fire({

                // title: 'Oops...',
                text: 'New Password Not Enter !',
                confirmButtonColor: '#e1563f'

            });

        }
    }

    // callBack() {
    //     if(this.props.userDetails.contactnumber != null){
    //         this.setState({ isLoadingModalOpen: true })
    //         let obj = {
    //             'query': `[dbo].[Lawcrux_Web_Proc_HelpDesk_Submit]
    //             @UserID='{0}',
    //             @ContactNumber='{1}',
    //             @Name='{2}'`,
    //             'queryArr': [`${this.props.userDetails.recid}`, `${this.props.userDetails.contactnumber}`, `${this.props.userDetails.username}`]
    //         }
    //         Hexapi(obj).then(resp => {
    //             console.log(resp)
    //             if (resp[''][0].command == '1') {
    //                 Swal.fire({
    //                     html: resp[''][0].msg,
    //                     allowOutsideClick: false,
    //                     confirmButtonColor: 'var(--Primary)',
    //                 }).then(re => {
    //                     this.props.closeModal()
    //                 })
    //             } else {
    //                 this.setState({ openInstantSupport: true })
    //             }

    //         }).then(() => this.setState({ isLoadingModalOpen: false }))
    //     }else{
    //         Swal.fire({
    //             html: 'Phone number not available.',
    //             allowOutsideClick: false,
    //             confirmButtonColor: 'var(--Primary)',
    //         }).then(re => {
    //             // this.props.closeModal()
    //         })
    //     }
    // }
    render() {
        console.log(this.props)
        return (
            <>
                {
                    this.state.isComponentRender &&
                    <Modal centered id="supportModal" animation={true} show={true} onHide={() => this.props.closeModal()} style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} backdrop="static" keyboard={false}>
                        <Modal.Header>
                            <div className="header">
                                <span>Change Password</span>
                                <IconButton onClick={() => this.props.closeModal()}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ height: "auto", padding: '10px' }}>
                                <Password
                                    labelStyle={{ fontWeight: "600" }}
                                    label="New Password"
                                    type="text"
                                    value={this.state.newpassword}
                                    className="form-control"
                                    onChange={(e) => this.storepassword(e)}
                                    name="newpassword"
                                    inline={false}
                                />
                                <Button id="btn1" buttonTitle="Submit" onClick={() => this.showdiv()} />
                                {/* <div className="descrition">
                                    <span>We will call you at <b>{this.props.userDetails.contactnumber != null ? this.props.userDetails.contactnumber : "Your Phone Number"}</b> within 24 Hours.
                                        If your phone number is not updated please update through <b>My Account</b></span>
                                </div> */}

                                {/* <div id="descrition" style={{display:"none"}}>
                                    <span id="title">Your password will change within 24 Hours.</span>
                                </div> */}
                            </div>
                        </Modal.Body>
                    </Modal>
                }

                <Modal centered id="supportModal" animation={true} show={this.state.openInstantSupport} onHide={() => this.setState({ openInstantSupport: false })} style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
                    <Modal.Header>
                        <div className="header">
                            <span></span>
                            <IconButton onClick={() => this.setState({ openInstantSupport: false })}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ minHeight: "100px", display: 'flex', alignItems: "center", textAlign: "center", padding: '10px' }}>
                            <span style={{ fontSize: "18px" }}>Call/Whatsapp at <b>9212336582</b> or Mail us at <a href="mailto:support@lawcrux.com">support@lawcrux.com</a></span>
                        </div>
                    </Modal.Body>
                </Modal>


                <Modal centered size="sm" id="loadingModal" animation={true} show={this.state.isLoadingModalOpen} onHide={() => this.setState({ isLoadingModalOpen: false })} style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} backdrop="static" keyboard={false}>
                    <Modal.Body>
                        <div style={{ display: 'flex', alignItems: 'center', padding: "8px", paddingBottom: "16px" }}>
                            <Spinner variant='dark' animation='border' />
                            <span style={{ paddingLeft: "20px" }}>{this.state.queryError}</span>
                            <span style={{ paddingLeft: "20px" }}>Loading...</span>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
