import axios from 'axios'
import { StringFormatter } from './StringFormatter';
import {Aesdecryption,Aesencryption} from '../Aes/Aes'
let data=""
const getapi=async()=>{
    const api= await axios.get('/apiinfo.json')
    data=api.data.Apipathurl
    console.log(api,"apihex------------")
}
getapi()
const Hexapi = (props) => new Promise((resolve,reject) => {
   if(data !==""){
    let fd = new FormData();
    if(props.file != undefined){
        fd.append(Aesencryption("file"),props.file)
    }
    if(props.orgdbname != undefined){
        fd.append(Aesencryption('orgdbname'),Aesencryption(props.orgdbname))
    }
    if(props.phoneno != undefined){
        fd.append(Aesencryption('phoneno'),Aesencryption(props.phoneno))
    }
    axios({
        mode: 'cors',
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        url: `${data}getOtp.php`,
        data: fd
    }).then(resp => {
        let decrypted = Aesdecryption("ncB5Fb1eClX32dogzN+9Kw==")
        if(JSON.parse(decrypted)){
            resolve(JSON.parse(decrypted))
        }else{
            reject('not get any response')
        }
        console.log(decrypted)

    }).catch(err => console.error(`axios error in query : ${props.phoneno}`,err))
   }
})

export default Hexapi;