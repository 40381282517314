import Webcam from 'react-webcam';
import { useRef, useState, useCallback, useEffect } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ClearIcon from '@mui/icons-material/Clear';
import zIndex from '@mui/material/styles/zIndex';
import './setimage.css'
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';


// const debounce = (func, delay) => {
//   let timer;
//   return function (...args) {
//     clearTimeout(timer);
//     timer = setTimeout(() => func.apply(this, args), delay);
//   };
// };

function Webcamera(props) {

  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [camera, setcamera] = useState(true);
  const [cameramode, setcameramod] = useState('user');
  const [mirror, setmirror] = useState(false);

  const [cameraDevices, setCameraDevices] = useState([]);
  const [selectedCameraDeviceId, setSelectedCameraDeviceId] = useState(null);


  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    // console.log(imageSrc)

    props.imageurl64(imageSrc)

  }, [webcamRef, setImgSrc]);


  const videoConstraints = {
    facingMode: { exact: cameramode },
    // mirrored: mirror,
  };


  const handleCameraChange = (event) => {
    const deviceId = event.target.value;
    setSelectedCameraDeviceId(deviceId);
  };

  useEffect(() => {
    const getCameraDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameraDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setCameraDevices(cameraDevices);
      if (cameraDevices.length > 0) {
        setSelectedCameraDeviceId(cameraDevices[0].deviceId);
      }
    };

    getCameraDevices();
  }, []);


  // const videoConstraints = selectedCameraDeviceId
  //   ? { deviceId: { exact: selectedCameraDeviceId } }
  //   : true;

  // const changecamera = () => {
  //   if (camera == false) {
  //     setcameramod('user');
  //   } else {
  //     setcameramod('environment')
  //   }

  //   // setTimeout(() => {
  //   //   setmirror(!mirror);
  //   // }, 635);

  // }



  // const handleCameraSwitch = useCallback(
  //   debounce(() => {
  //     setcamera((prevCamera) => !prevCamera);
  //     changecamera();
  //   }, 1000),
  //   []
  // );


  return (
    <>

      <div style={{ display: "flex", justifyContent: "space-between", padding: '10px 0px' }}>
        <span id='CameraAlt' style={{ border: '2px solid black', height: 70, width: 70, position: 'absolute', top: '390px', left: '45%', borderRadius: '50%' }}>
          <CameraAltIcon id='CameraAltIcon' onClick={capture} style={{ cursor: "pointer", position: 'absolute', right: 13, border: '0px solid black', top: 13, height: '40px', width: '40px', borderRadius: '50%' }} />
        </span>

        {/* <CameraswitchIcon
          onClick={() => { setcamera((prevCamera) => !prevCamera); changecamera(); }}
          style={{ cursor: "pointer", position: 'absolute', left: '50%', left: '85%', top: '400px', zIndex: '1', height: '50px', width: '50px ' }} /> */}

        <ClearIcon onClick={props.closecameramodal} style={{ cursor: "pointer", position: 'absolute', left: '92%', top: '10px', zIndex: 1 }} id='closecamera' />
      </div>

      <select value={selectedCameraDeviceId} onChange={handleCameraChange} style={{borderRadius:' 5px',border:' 2px solid black',marginBottom: '5px'}}>
        {cameraDevices.map((device) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label}
          </option>
        ))}
      </select>

      <Webcam
        audio={false}
        style={{
          position: 'relative',
          // Use the rotate class when cameraSwitching is true
          // transform: cameraSwitching ? 'rotate(180deg)' : 'none',
          // transition: 'transform 0.7s ease',
        }}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        height={320}
        screenshotQuality={1}
        imageSmoothing={true}
        width={'100%'}
        videoConstraints={videoConstraints}
        mirrored={true}
        id='webcam'
      // mirrored={false}
      />

      {/* <div style={{width:'100%',height:'340px'}}></div> */}



      {/* <button onClick={capture}>Capture photo</button> */}
      {/* {imgSrc && (
      <img
        src={imgSrc}
      />
    )} */}
    </>
  );
};


export default Webcamera;
