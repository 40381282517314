import React, { useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {SideBar} from "./sidebar/SideBar";
import './App.css'
import { BrowserRouter,Route,Routes} from "react-router-dom";
import Visitorlog from "./pages/visitorlog/visitorlog";
import Homepg from "./pages/livetable/homepg";
import WelcomeFrom from "./pages/entryform/WelcomeForm";
import Login from "./pages/login/Login"
import Protectedroutes from "./Protectedroutes";
export default function App() {
  
  return (
 
    <BrowserRouter>
        <Routes>
        <Route path="/Entryform" element={<WelcomeFrom/>} />
          <Route path="/login" element={<Login/>} />
          <Route element={<Protectedroutes />}>
            <Route path="/" element={<SideBar />}>
              <Route index path="/" element={<Homepg />} />
              <Route exact path="/HomePage" element={<Homepg />} />
              <Route path="/Visitorlogs" element={<Visitorlog />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
   
  );
}
