import React, { Component } from 'react'
import TablePagination from '@mui/material/TablePagination';
export default class PaginationBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 0
        }
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    }

    componentDidMount() {
        this.setState({ rowsPerPage: this.props.rowsPerPage })
    }
    handleChangePage(e, newPage) {
        e.preventDefault()
        this.setState({ page: newPage }, () => this.props.onPageChange(newPage))
    }

    handleChangeRowsPerPage(e) {
        console.log(e.target.value)
        this.setState({
            rowsPerPage: parseInt(e.target.value, 10),
            page: 0
        }, () => this.props.onRowsChange(e.target.value))
    }
    componentDidUpdate(prevProps) {
        console.log(prevProps.count,this.props.count,this.state.rowsPerPage,this.state.page)
        if (prevProps.count != this.props.count) {
            this.setState({ page: 0 })
            console.log(this.state.rowsPerPage, this.props.count)
        }
    }
    render() {
        console.log(this.state)
        return (
            <div className='paginationBar'>
                <div>
                    <TablePagination
                    style={{color:" #ffff"}}
                        component="div"
                        rowsPerPageOptions={this.props.rowsPerPageOptions}
                        rowsPerPage={this.state.rowsPerPage}
                        count={this.props.count}
                        page={this.state.page}
                        labelRowsPerPage={'Maximun rows per page'}
                        onPageChange={(e, newPage) => this.handleChangePage(e, newPage)}
                        onRowsPerPageChange={(e) => this.handleChangeRowsPerPage(e)}
                    />
                </div>
            </div>
        )
    }
}

